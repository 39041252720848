
	import { Component, Vue, Watch } from "vue-property-decorator";
	import request from "@/request";
	import { ECharts, EChartsOption, init } from "echarts";
	import { Table, TableColumn, Modal } from "view-design";
	import Loading from "@/components/loading/Loading.vue";

	const step0 = require("@/assets/dealing.png");
	const step1 = require("@/assets/未开始.png");
	const step2 = require("@/assets/处理中.png");
	const step3 = require("@/assets/已完成.png");
	const step4 = require("@/assets/已关闭.png");
	@Component({
		components: {
			Loading,
			Table, Modal
		}
	})
		export default class gasMain extends Vue {
            colors = ["#4261ED", "#EF9F24", "#c4ccD6", "#FD4848"];
            showContent = false;
            isDrawBack = false;
		floors:{ name:string, id:string }[] = [];
		// 列表格式定义
		readonly columns:TableColumn[] = [
			{
				title: "编号",
				key: "code",
				align: "center",
				width: 45
			},
			{
				title: "设备位置",
				key: "location",
				align: "center",
				width: 100
			},
			{
				title: "CO ppm",
				key: "number",
				align: "center",
				width: 70
			},
			{
				title: "状态",
				key: "status",
				align: "center",
				render: (h, params) => {
					return h("img", {
						style: {// 设置样式
							width: "20px",
							"height": "20px"
						},
						attrs: {// 设置属性
							src: params.index < 2 ? require("@/assets/icon/on.png") : params.index > 3 ? require("@/assets/icon/baojing_icon.png") : require("@/assets/icon/no.png")
						}
					});
				}
			},
			{
				title: "更新时间",
				key: "time",
				align: "center",
				width: 85
			}
		];
		// 列表数据
		data:any[] = [
			{
				code: "001",
				location: "1F101实验室",
				number: 0.12,
				status: 1,
				time: "10.10 10:20",
                img: require("@/assets/icon/on.png")
			},
			{
				code: "002",
				location: "1F101实验室",
				number: 0.12,
				status: 1,
                img: require("@/assets/icon/on.png"),
				time: "10.10 10:20"
			},
			{
				code: "003",
				location: "1F101实验室",
				number: 0.12,
				status: 1,
                img: require("@/assets/icon/baojing_icon.png"),
				time: "10.10 10:20"
			},
			{
				code: "004",
				location: "1F101实验室",
				number: 0.12,
				status: 1,
                img: require("@/assets/icon/yujing.png"),
				time: "10.10 10:20"
			},
			{
				code: "005",
				location: "1F101实验室",
				number: 0.12,
				status: 1,
                img: require("@/assets/icon/no.png"),
				time: "10.10 10:20"
			}
		];
		// 表格加载中
		tableLoading:boolean = false;

		// 监测历史曲线图 CO
		getHisEcharts (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			// @ts-ignore
			const option: EChartsOption = {
                title: {
                    text: "近一周报警轨迹图",
                    textStyle: {
                        color: "#515a6e",
                        fontWeight: 600,
                        fontSize: 14
                    },
                    left: 15,
                    top: 10
                },
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    icon: "circle",
                    data: ["PM2.5", "CO", "CO2"],
                    textStyle: { // 图例文字的样式
                        color: "#788CA1",
                        fontSize: 12
                    },
                    top: 35
                },
                grid: {
                    top: "26%",
                    left: "8%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true
                },
                toolbox: {
                    show: false,
                feature: {
                    saveAsImage: {}
                }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: ["8-11", "8-12", "8-13", "8-14", "8-15", "8-16", "8-17"],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#c4ccD6"
                        }
                    },
                    splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: ["#e0e2e3"]
                            }
                        },
                    axisTick: {
                        lineStyle: {
                            width: 0
                        }
                    },
                    axisLabel: {
                        color: "#788CA1"
                    }
                },
                yAxis: {
                    type: "value",
                    nameTextStyle: {
                        fontSize: 10,
                        align: "center"
                    },
                    axisLabel: {
                        color: "#788CA1"
                    },
                    splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: ["#e0e2e3"]
                            }
                        },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#c4ccD6"
                        }
                    }
                },
                series: [
                    {
                        name: "PM2.5",
                        type: "line",
                        smooth: true,
                        symbol: "none",
                        data: [12, 13, 10, 13, 9, 23, 20],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    {
                        name: "CO",
                        type: "line",
                        smooth: true,
                        symbol: "none",
                        data: [22, 18, 1, 24, 9, 3, 3],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[1] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    },
                    {
                        name: "CO2",
                        type: "line",
                        smooth: true,
                        symbol: "none",
                        data: [15, 22, 1, 14, 19, 30, 10],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[2] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ],
                color: this.colors
            };
			option && chart.setOption(option);
		}
		// 左侧监测历史曲线图 CO
		getHisLeftEcharts2 () {
			const myChart: HTMLElement = document.getElementById("echartLeft") as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			// @ts-ignore
			const option: EChartsOption = {
                title: {
                    text: "近一周报警轨迹图",
                    textStyle: {
                        color: "#515a6e",
                        fontWeight: 600,
                        fontSize: 14
                    },
                    left: 15,
                    top: 10
                },
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    icon: "circle",
                    data: ["CO"],
                    textStyle: { // 图例文字的样式
                        color: "#788CA1",
                        fontSize: 12
                    },
                    top: 30
                },
                grid: {
                    top: "28%",
                    left: "0%",
                    right: "4%",
                    bottom: "0%",
                    containLabel: true
                },
                toolbox: {
                    show: false,
                feature: {
                    saveAsImage: {}
                }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: ["一", "二", "三", "四", "五", "六", "日"],
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#c4ccD6"
                        }
                    },
                    splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: ["#e0e2e3"]
                            }
                        },
                    axisTick: {
                        lineStyle: {
                            width: 0
                        }
                    },
                    axisLabel: {
                        color: "#788CA1"
                    }
                },
                yAxis: {
                    type: "value",
                    nameTextStyle: {
                        fontSize: 10,
                        align: "center"
                    },
                    axisLabel: {
                        color: "#788CA1"
                    },
                    splitLine: {
                            show: true,
                            lineStyle: {
                                type: "dashed",
                                color: ["#e0e2e3"]
                            }
                        },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#c4ccD6"
                        }
                    }
                },
                series: [
                    {
                        name: "CO",
                        type: "line",
                        smooth: true,
                        symbol: "none",
                        data: [22, 18, 1, 24, 9, 3, 3],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
                    }
                ],
                color: this.colors
            };
			option && chart.setOption(option);
		}
        setChart4 (domName) {
			const myChart: HTMLElement = document.getElementById(domName) as HTMLElement;
			// 获取dom，断言HTMLElement类型，否则会报错
			const chart = init(myChart);
			let myOption:any = {
                title: {
                    text: "近一周报警轨迹",
                    textStyle: {
                        color: "#515a6e",
                        fontWeight: 600,
                        fontSize: 14
                    }
                },
                legend: {
                    data: ["CO", "CO2"],
                    itemWidth: 13,
                    itemHeight: 13,
                    textStyle: {
                        color: "#788CA1"
                    },
                    top: 25
                },
				grid: {
                    top: "25%",
					left: "5%",
					right: "4%",
					bottom: "3%",
					containLabel: true
				},
				xAxis: {
					type: "category",
					boundaryGap: false,
                    axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisTick: {
						lineStyle: {
							width: 0
						}
					},
					axisLabel: {
						color: "#788CA1"
					},
					data: ["8-12", "8-13", "8-14", "8-15", "8-16", "8-17", "8-18"]
				},
				yAxis: {
					type: "value",
                    nameTextStyle: {
						fontSize: 10,
						align: "center",
						color: "#788CA1"
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: "dashed",
                            color: ["#e0e2e3"]
						}
					},
					axisLine: {
						show: true,
						lineStyle: {
							color: "#c4ccD6"
						}
					},
                    axisLabel: {
						color: "#788CA1"
					}
				},
                tooltip: {
                    trigger: "axis"
                },
				series: [
					{
						name: "CO",
						type: "line",
                        smooth: true,
					    symbol: "none",
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[0] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
						data: [100, 120, 150, 160, 80, 200, 200]
					},
                    {
						name: "CO2",
						type: "line",
                        smooth: true,
					    symbol: "none",
						data: [120, 132, 101, 134, 90, 230, 210],
                        areaStyle: {
                            opacity: 0.5,
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: this.colors[1] // 0% 处的颜色
                                }, {
                                    offset: 1, color: "#ffffff" // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        }
					}
				],
                color: this.colors
			};
			chart.setOption(myOption);
		}
		// 点击列表
		onSelect (value:any) {
			// console.log(value);
            this.showContent = true;
		}
		mounted () {
            this.setChart4("echart");
			this.getHisEcharts("echartC");
			this.getHisEcharts("echartCO");
		}
        @Watch("showContent")
		onShowContentChange () {
            this.$nextTick(() => {
			    this.getHisLeftEcharts2();
            });
		}
        @Watch("$store.state.shebeiModal")
        changeshebeiModal () {
            this.showContent = true;
        }
        @Watch("isDrawBack")
        onisDrawBackchange (val) {
            this.$store.commit("changeDraw", val);
        }
	}

